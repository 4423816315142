<template>
  <VueFinalModal name="giveaway_create" v-bind="$attrs" classes="modal-container" content-class="modal-content" @before-close="onBeforeClose" @before-open="onBeforeOpen">
    <div id="giveawayModal" class="box-modal gw">
      <div class="modal-header f">
        <div class="title">{{ $t("giveaway.setting") }}</div>
        <button class="btn_close" @click.prevent="$vfm.hide('giveaway_create')">
          <img class="close" src="/icons/close.svg" alt="" />
        </button>
      </div>
      <div class="modal-body">
        <div v-if="haveGiveaways" class="gw_wrap">
          <div class="gw_loading">
            <i class="i-loading" />
            <span>{{ $t("page.casino.game.loading") }}</span>
          </div>
        </div>
        <div v-else class="gw_wrap">
          <div class="gw-tabs">
            <button @click="selectTab('all')" type="button" :class="{ active: users === 'all' }">
              {{ $t("giveaway.for_al") }}
            </button>
            <button @click="selectTab('referral')" type="button" :class="{ active: users === 'referral' }">
              {{ $t("giveaway.for_refs") }}
            </button>
          </div>
          <div class="gw-switch">
            <div class="gw-switch_text">{{ $t("giveaway.long") }}</div>
            <VToggle class="gw-switch_switch" v-model="long" />
          </div>
          <div class="gw-option">
            <div class="gw-option_label">{{ $t("giveaway.winning_count") }}</div>
            <div class="gw-option_wrap">
              <UiSelect v-model="winnerCount" :options="winnersList" />
            </div>
          </div>
          <div class="gw-option">
            <div class="gw-option_label">{{ $t("giveaway.time") }}</div>
            <div class="gw-option_wrap">
              <UiSelect v-model="time" :options="timeList" />
            </div>
          </div>
          <div class="gw-option">
            <div class="gw-option_label">{{ $t("giveaway.amount") }}</div>
            <div class="gw-option_wrap">
              <div class="input_gradient">
                <input v-model="amount" type="number" :placeholder="`${minDep} ₽`" />
                <span></span>
              </div>
            </div>
          </div>
          <div class="gw-option">
            <div class="gw-option_label">{{ $t("giveaway.deposit_of") }}</div>
            <div class="gw-option_wrap">
              <UiSelect v-model="deposits" :options="depositList" ontop />
            </div>
          </div>
          <div class="gw-footer">
            <button class="bt bt-orange" @click="create()">
              {{ $t("giveaway.start") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </VueFinalModal>
</template>

<script setup>
import { VueFinalModal } from "vue-final-modal";
import { $vfm } from "vue-final-modal";
import copier from "clipboard-copy";
const { t } = useI18n();
const onBeforeOpen = (e) => {
  checkOpenedGiveaways();
};
const onBeforeClose = (e) => {
  haveGiveaways.value = true;
};
const haveGiveaways = ref(true);
const loading = ref(false);
const amount = ref(null);
const long = ref(false);
const winnerCount = ref(1);
const winnersList = computed(() => (long.value ? [5, 10, 15, 20, 25, 30, 50, 100] : [1, 2, 3]));
const time = ref(30);
const minDep = ref(150);
const timeList = computed(() =>
  long.value
    ? [
        {
          value: 60 * 60,
          label: `1 ${t("time.hours")}`,
        },
        {
          value: 60 * 60 * 2,
          label: `2 ${t("time.hours")}`,
        },
        {
          value: 60 * 60 * 12,
          label: `12 ${t("time.hours")}`,
        },
        {
          value: 60 * 60 * 24,
          label: `24 ${t("time.hours")}`,
        },
        {
          value: 60 * 60 * 48,
          label: `48 ${t("time.hours")}`,
        },
        {
          value: 60 * 60 * 72,
          label: `72 ${t("time.hours")}`,
        },
        {
          value: 60 * 60 * 168,
          label: `168 ${t("time.hours")}`,
        },
      ]
    : [
        {
          value: 30,
          label: `30 ${t("time.sec")}`,
        },
        {
          value: 60,
          label: `1 ${t("time.min")}`,
        },
        {
          value: 120,
          label: `2 ${t("time.min")}`,
        },
        {
          value: 300,
          label: `5 ${t("time.min")}`,
        },
        {
          value: 600,
          label: `10 ${t("time.min")}`,
        },
      ]
);
const deposits = ref("1000");
const depositList = ref([
  {
    value: "0",
    label: `0₽`,
  },
  {
    value: "100",
    label: `100₽`,
  },
  {
    value: "500",
    label: `500₽`,
  },
  {
    value: "1000",
    label: `1000₽`,
  },
  {
    value: "5000",
    label: `5000₽`,
  },
  {
    value: "10000",
    label: `10000₽`,
  },
  {
    value: "50000",
    label: `50000₽`,
  },
  {
    value: "100000",
    label: `100000₽`,
  },
]);
const users = ref("all");
function selectTab(tab) {
  users.value = tab;
}

watch(
  () => long.value,
  () => {
    winnerCount.value = winnersList.value[0];
    time.value = timeList.value[0].value;
  }
);

const { $axios, $auth, $showToast } = useNuxtApp();
const create = async () => {
  if (loading.value) return;
  if (amount.value < minDep.value) return $showToast(`${t("giveaway.set_amount")} ${minDep.value}₽`, "error");
  loading.value = true;
  const body = {
    amount: amount.value,
    rules: {
      time: time.value,
      users: users.value,
      winnerCount: winnerCount.value,
      deposits: Number(deposits.value),
    },
  };
  await $axios
    .$post("/bonus/giveaways", body)
    .then(({ response }) => {
      if (response.giveaway) {
        const item = response.giveaway;
        const code = item.link.substring(item.link.indexOf("g=") + 2, item.link.length);
        $auth.user.money = response.balance;
        copier(item.link);
        $showToast(t("giveaway.copied"), "success");
        setTimeout(() => {
          $vfm.show("giveaway", { code });
        }, 300);
        $vfm.hide("giveaway_create");
      }
    })
    .finally(() => {
      loading.value = false;
    });
};
const checkOpenedGiveaways = async () => {
  await $axios.$get("/bonus/giveaways", { params: { active: 1 } }).then(({ response }) => {
    if (response.data.length) {
      const item = response.data[0];
      const code = item.link.substring(item.link.indexOf("g=") + 2, item.link.length);
      $vfm.show("giveaway", { code });
      $vfm.hide("giveaway_create");
    } else {
      haveGiveaways.value = false;
    }
  });
};
</script>

<style lang="scss">
.gw-switch {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;

  &_text {
    font-style: 14px;
    font-weight: 600;
  }
}
</style>
