<script setup>
const props = defineProps({
  modelValue: {
    type: [Number, String],
    default: null,
  },
  placeholder: {
    type: String,
    default: "Выберите ...",
  },
  options: {
    type: Array,
    default: () => [],
  },
  ontop: {
    type: Boolean,
    default: false,
  },
});
const dd = ref(null);
const toggle = ref(false);
const emit = defineEmits(["update:modelValue"]);
const selected = computed({
  get() {
    const v = props.options[0]?.value
      ? props.options.find((el) => el.value === props.modelValue)?.label
      : props.modelValue;
    return v;
  },
  set(val) {
    emit("update:modelValue", val);
  },
});
const select = (v) => {
  emit("update:modelValue", v.value || v);
  toggle.value = false;
};
const documentClick = (event) => {
  let target = event.target;
  if (dd && dd.value && dd.value !== target && !dd.value.contains(target)) {
    toggle.value = false;
  }
};
onMounted(() => {
  document.addEventListener("click", documentClick);
});
onBeforeUnmount(() => {
  document.removeEventListener("click", documentClick);
});
</script>

<template>
  <div ref="dd" class="cselect" :class="{ open: toggle, ontop }">
    <button class="cselect_top" @click="toggle = !toggle">
      <span>{{ selected || $t(placeholder) }}</span>
      <FontIcon icon="arrow" size="16" class="arrow" />
    </button>
    <div class="cselect_dropdown">
      <div class="cselect_overlay" @click="toggle = !toggle" />
      <div class="list cselect__list">
        <button
          v-for="item in options"
          :key="item.value || item"
          class="btn"
          :class="{ active: selected === (item.label || item) }"
          @click="select(item)"
        >
          <span>{{ $t(item.label || item) }}</span>
          <FontIcon
            v-if="selected === (item.label || item)"
            icon="check"
            size="16"
            class="check"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>

<style lang="scss" scoped>
@mixin ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.cselect {
  position: relative;
  font-size: 14px;
  width: 100%;
  max-width: initial;

  .arrow,
  .check {
    color: var(--primary-300);
  }

  & > .cselect {
    &_top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      padding: 1px 16px;
      height: 48px;
      border-radius: 4px;
      font-weight: 600;
      width: 100%;
      @include ellipsis;
      transition: 0.3s;
      background: var(--primary-100);
      text-align: left;
      font-size: 15px;

      span {
        width: 100%;
        color: var(--primary-800) !important;
      }

      .arrow {
        transition: 0.3s;
      }

      &.transparent {
        background: transparent !important;
      }
    }

    &_dropdown {
      position: absolute;
      z-index: 50;
      top: 110%;
      left: -10px;
      right: -10px;
      padding: 10px;
      opacity: 0;
      visibility: hidden;
      transition: 0.3s;
      min-width: 100%;

      .list {
        background: var(--primary-100);
        border-radius: 4px;
        padding: 2px 3px;
        overflow-y: auto;
        max-height: 430px;
        box-shadow: 0px 2px 8px rgba(42, 36, 68, 0.2) !important;

        .btn {
          @include ellipsis;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 12px;
          font-size: 15px;
          font-weight: 600;
          width: 100%;
          background: none;
          gap: 8px;
          text-align: left;
          height: 48px;
          border-radius: 4px;
          margin: 2px 0;

          span {
            width: 100%;
            color: var(--primary-800) !important;
          }

          &:hover {
            background: var(--primary-bg);
          }

          &.active {
            background: var(--primary-bg);
          }
        }
      }
    }
  }

  &.open {
    & > .cselect {
      &_top {
        .arrow {
          transform: rotate(180deg);
        }
      }

      &_dropdown {
        top: 100%;
        opacity: 1;
        visibility: visible;
      }
    }

    &.ontop {
      & > .cselect {
        &_dropdown {
          top: inherit;
          bottom: 100%;
        }
      }
    }
  }

  &.ontop {
    & > .cselect {
      &_dropdown {
        top: inherit;
        bottom: 90%;
      }
    }
  }
}
</style>
